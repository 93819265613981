import { GVInfoBar, GVInput, GVModal } from "@isc/egov-ui/src";
import { GVFab } from "@isc/egov-ui/src/GVFab/GVFab";
import { GVList } from "@isc/egov-ui/src/GVList/GVList";
import { GVStep } from "@isc/egov-ui/src/GVStep/GVStep";
import { GVStepper } from "@isc/egov-ui/src/GVStepper/GVStepper";
import { GVListItem } from "@isc/egov-ui/src/GVListItem/GVListItem";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapConsumer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

import React from "react";
import { Geolocation } from "../logic/Geolocation";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { LatLng } from "leaflet";

import L from "leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { hideOverlay, showOverlay } from "../store/slices/overlay";
import { connect } from "react-redux";
import { StorageManager } from "../logic/StorageManager";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Shot {
  animal: string;
  weapon: string;
  position: LatLng;
}

interface HuntingServiceState {
  activeStep: number;
  animal: string;
  city: string;
  isModalShotOpen: boolean;
  isModalFinishOpen: boolean;
  position: LatLng;
  shots: Shot[];
  weapon: string;
}

interface RouterProps {
  // type for `match.params`
  id: string; // must be type `string` since value comes from the URL
}

interface HuntingServiceProps extends RouteComponentProps<RouterProps> {
  // any other props (leave empty if none)
  dispatch: any;
}
class HuntingService extends React.Component<
  HuntingServiceProps,
  HuntingServiceState
> {
  public constructor(props: HuntingServiceProps) {
    super(props);
    this.state = {
      activeStep: 0,
      animal: "",
      city: "",
      isModalShotOpen: false,
      isModalFinishOpen: false,
      shots: [],
      position: new LatLng(0, 0),
      weapon: "",
    };
  }

  public componentDidMount = async () => {
    const city = await Geolocation.getCurrentCity();
    const position = await Geolocation.getCurrentPosition();
    this.setState({
      city: city,
      position: position,
    });
  };

  private mapCreated = async () => {
    const position = await Geolocation.getCurrentPosition();
    console.log("OK");
    this.setState({
      position: position,
    });
  };

  private addShot = () => {
    this.setState({
      isModalShotOpen: true,
    });
  };

  private clickAddShot = async () => {
    const currentPos = await Geolocation.getCurrentPosition();
    this.setState((prevState: HuntingServiceState) => ({
      shots: [
        ...prevState.shots,
        {
          animal: this.state.animal,
          weapon: this.state.weapon,
          position: currentPos,
        },
      ],
      animal: "",
      weapon: "",
      isModalShotOpen: false,
    }));
  };

  private clickDoNotAddShot = () => {
    this.setState({
      isModalShotOpen: false,
    });
  };

  private handleCloseFinish = () => {
    this.setState({ isModalFinishOpen: false });
  };

  private handleAgreeFinish = () => {
    console.log(this.state);
    this.setState({ isModalFinishOpen: false });
    const isOnline = navigator.onLine;
    this.props.dispatch(
      showOverlay(
        isOnline
          ? "Sending hunting session data to server..."
          : "You're offline. Storing session hunting data..."
      )
    );
    if (!isOnline) {
      StorageManager.store("services", [
        {
          name: "Hunting",
          data: {
            city: this.state.city,
            shots: this.state.shots,
            position: this.state.position,
          },
        },
      ]);
    }
    setTimeout(() => {
      this.props.history.push("/Home");
      this.props.dispatch(hideOverlay());
    }, 1000);
  };

  private changeStep = (increment: boolean) => {
    this.setState((prevState: HuntingServiceState) => ({
      activeStep: prevState.activeStep + (increment ? 1 : -1),
    }));
  };

  private onBackClick = (e: any) => {
    this.changeStep(false);
  };

  private onNextClick = (e: any) => {
    this.changeStep(true);
  };

  private onSendClick = (e: any) => {
    this.setState({ isModalFinishOpen: true });
  };

  render(): any {
    const {
      position,
      isModalShotOpen,
      isModalFinishOpen,
      shots,
      animal,
      weapon,
      activeStep,
    } = this.state;
    return (
      <>
        <h2>Session de chasse</h2>
        <GVStepper
          activeStep={activeStep}
          onNextClick={this.onNextClick}
          onBackClick={this.onBackClick}
          onSendClick={this.onSendClick}
        >
          <GVStep title="First">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
              qui aliquid officiis voluptatum doloribus, nulla delectus.
            </p>
            <GVInput
              id="location"
              label="Your location"
              defaultValue={this.state.city}
              error={false}
            />
          </GVStep>
          <GVStep title="Hunt">
            <div style={{ width: "100%" }}>
              {navigator.onLine ? (
                activeStep === 1 && (
                  <MapContainer
                    center={[position.lat, position.lng]}
                    whenCreated={this.mapCreated}
                    whenReady={this.mapCreated}
                    zoom={12}
                    touchZoom={true}
                    dragging={true}
                    style={{
                      width: "100%",
                      height: "40vh",
                    }}
                    scrollWheelZoom={false}
                  >
                    <MapConsumer>
                      {(map) => {
                        map.setView(position, 14);
                        return null;
                      }}
                    </MapConsumer>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {shots.map((shot: Shot, index: number) => (
                      <Marker key={index} position={shot.position}>
                        <Popup>
                          {shot.animal} <br /> {shot.weapon}
                        </Popup>
                      </Marker>
                    ))}
                  </MapContainer>
                )
              ) : (
                <div className="w100">
                  <GVInfoBar variant="warning">
                    It seems you are offline !
                  </GVInfoBar>
                </div>
              )}

              <GVFab
                id="btn-add-shot"
                type="primary"
                icon="add"
                onClick={this.addShot}
              />

              <GVModal
                title="Add a shot"
                typeAgree="primary"
                handleAgree={this.clickAddShot}
                agree="Add a shot"
                disagree="cancel"
                typeDisagree="text"
                handleDisagree={this.clickDoNotAddShot}
                open={isModalShotOpen}
              >
                <GVInput
                  id="race"
                  label="Bête"
                  defaultValue={animal}
                  onChange={(e) => this.setState({ animal: e.target.value })}
                />
                <GVInput
                  id="method"
                  label="Shot method"
                  defaultValue={weapon}
                  onChange={(e) => this.setState({ weapon: e.target.value })}
                />
              </GVModal>
            </div>
          </GVStep>
          <GVStep title="Infos">
            <div>
              <GVList>
                {shots.length > 0 ? (
                  shots.map((shot: Shot, index: number) => (
                    <GVListItem key={index} icon="pets">
                      <span>{shot.animal}</span>
                      <span>{shot.weapon}</span>
                      <span>
                        {shot.position.lat}, {shot.position.lng}
                      </span>
                    </GVListItem>
                  ))
                ) : (
                  <GVInfoBar variant="warning">
                    Be carefull, you have not added any shot yet !
                  </GVInfoBar>
                )}
              </GVList>
            </div>
          </GVStep>
        </GVStepper>
        <GVModal
          open={isModalFinishOpen}
          agree="Yes"
          title="Finish hunting session"
          typeAgree="primary"
          typeDisagree="gray"
          disagree="No"
          handleAgree={this.handleAgreeFinish}
          handleDisagree={this.handleCloseFinish}
          handleClose={this.handleCloseFinish}
        >
          <p>Are you sure you want to finish your session ?</p>
        </GVModal>
      </>
    );
  }
}

export default connect((state) => state)(withRouter(HuntingService));
