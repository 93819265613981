import { combineReducers } from '@reduxjs/toolkit';

// Get all reducers
import authReducer from "./slices/auth";
import overlayReducer from "./slices/overlay";
import notificationsReducer from "./slices/notifications";
import userReducer from "./slices/user";

// Combine to register a complete reducer
const rootReducer = combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
    overlay: overlayReducer,
    user: userReducer,
});

// Compute root state type
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;