import { GVDataTable } from "@isc/egov-ui/src/GVDataTable/GVDataTable";
import { GVIconButton } from "@isc/egov-ui/src/GVIconButton/GVIconButton";

import React from "react";

export default function DocumentsView() {
  const columns = [
    { field: "id", headerName: "ID", hide: true, width: 10 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "service", headerName: "Concerned Service", flex: 3 },
    {
      field: "description",
      headerName: "Description",
      type: "text",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.5,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (
        <GVIconButton
          variant="primary"
          icon="download"
          onClick={(e) => {
            console.log(e.target, params.row.id);
          }}
        ></GVIconButton>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 2,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 3,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 4,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 5,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 6,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 7,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
    {
      id: 8,
      date: "16.12.2020 à 18h40",
      service: "Extrait du registre du commerce",
      description: "CHE-406.266.691",
      actions: "",
    },
  ];

  return (
    <>
      <h2>My documents</h2>

      <GVDataTable rows={rows} columns={columns}/>
    </>
  );
}
