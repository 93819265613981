import { GVCard, GVGrid, GVGridItem } from "@isc/egov-ui/src";
import { GVInput } from "@isc/egov-ui/src/Form/GVInput/GVInput";
import { GVButton } from "@isc/egov-ui/src/GVButton/GVButton";

import React, { useState } from "react";
import StylesProvider from "@isc/egov-ui/src/Misc/GVStyleProvider/GVStyleProvider";
import {RouteComponentProps, useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { setAuthState } from "../store/slices/auth";

interface RouterProps {}

interface LoginViewProps extends RouteComponentProps<RouterProps> {}

export default function LoginView(props: LoginViewProps) {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(6, "Too Short!").required("Required"),
  });

  const dispatcher = useDispatch();

  const [credentialsError, setCredentialsError] = useState(false);

  const snackbar = useSnackbar();

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit(values) {
      if (
        formik.values.email === "yguerdat@artionet.com" &&
        formik.values.password === "demo1234"
      ) {
        snackbar.enqueueSnackbar("Welcome on ePortal !", {
          variant: "success",
        });
        setCredentialsError(false);
        dispatcher(setAuthState(true));
        history.push("/Home");
      } else {
        setCredentialsError(true);
      }
    },
  });

  return (
    <StylesProvider injectFirst>
      <div className="row horizontal-center vertical-center background-gradient vw100 vh100">
        <GVCard className="login-form">
          <GVGrid alignHorizontal="center" alignVertical="center">
            <div className="row column">
              <img
                src="https://www.igovportal.ch/Htdocs/Images/logo.svg"
                className="m-0-auto mt-1"
                alt=""
              />
              <p className="m-0-auto ta-center mt-2">
                This page allows developers to log into ePortal React version.
                This POC is developed by Lucas Fridez, Artionet Web Agency.
              </p>
            </div>
            <form className="row" onSubmit={formik.handleSubmit}>
              <GVGridItem allWidths={12}>
                <GVInput
                  id="email"
                  defaultValue={formik.values.email}
                  label="Email"
                  name="email"
                  required
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.email && formik.touched.email) as boolean
                  }
                  errorMessage={formik.errors.email}
                />
              </GVGridItem>
              <GVGridItem allWidths={12}>
                <GVInput
                  id="password"
                  type="password"
                  defaultValue={formik.values.password}
                  label="Password"
                  name="password"
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.password &&
                      formik.touched.password) as boolean
                  }
                  errorMessage={formik.errors.password}
                  required
                />
              </GVGridItem>
              {credentialsError && (
                <GVGridItem allWidths={12}>
                  <p className="ta-center">Credentials mismatch !</p>
                </GVGridItem>
              )}
              <GVGridItem allWidths={12} className="row horizontal-center">
                <GVButton
                  variant="submit"
                  icon="login"
                  title="Login"
                  type="primary"
                />
              </GVGridItem>
            </form>
          </GVGrid>
        </GVCard>
      </div>
    </StylesProvider>
  );
}
