import { GVRoute } from "./GVRoute";

type RouteName = "Login" | "SignUp" | "LoginProvider";

export interface RouteDom {
    name: string;
    path: string;
}

export class RoutesManager {

    public static ROUTES: Record<RouteName, GVRoute> = {
        Login: { path: "/Login", inContainer: false },
        SignUp: { path: "/SignUp", inContainer: false },
        LoginProvider: { path: "/LoginProviders", inContainer: false }
    }

    public static getRoutes(inContainer: boolean = true) {
        const routes: RouteDom[] = [];
        for (let [key, value] of Object.entries(this.ROUTES)) {
            if (value.inContainer === inContainer) {
                routes.push({ name: key, path: value.path });
            }
        }
        return routes;
    }
};