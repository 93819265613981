export interface ServiceData {
    name: string;
    data: string;
}

/**
 * Storage static class
 * Contains logic for storage in session/local Storage
 * ? Test IndexedDB
 *
 * @author Lucas Fridez <lucas.fridez@he-arc.ch>
 * @export
 * @class Validator
 */
export class StorageManager {

    public static checkServicesData(): ServiceData[] {
        const data = JSON.parse(window.localStorage.getItem("services") ?? "[]") as ServiceData[];
        return data;
    }

    public static store(key: string, value: any): void {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    private static pullData(key: string, defaultValue: any): any {
        const data = JSON.parse(window.localStorage.getItem(key) ?? defaultValue);
        window.localStorage.removeItem(key);
        return data;
    }

    public static pullObject(key: string): any {
        return this.pullData(key, "{}");
    }

    public static pullArray(key: string): any {
        return this.pullData(key, "[]");
    }
}