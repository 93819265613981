import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { ServicesList } from "../components/ServicesList";

interface RouterProps {
  theme?: string;
}

interface ServiceViewProps extends RouteComponentProps<RouterProps> {}

export default function ServiceView(props: ServiceViewProps) {
  

  // const isLoggedIn = useSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );

  // const dispatch = useAppDispatch();
  // console.log("OK", isLoggedIn);

  // setTimeout(() => {
  //   dispatch(setAuthState(false));
  // }, 1000);

  return (
    <>
      <h2>{props.match.params.theme ?? "All themes"}</h2>
      <h3>Services list</h3>
      <ServicesList theme={props.match.params.theme} />
    </>
  );
}
