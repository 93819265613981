import { GVGrid, GVGridItem, GVServiceCard } from "@isc/egov-ui/src";
import { GVServiceCardProps } from "@isc/egov-ui/src/GVServiceCard/GVServiceCard.types";
import React from "react";
import { Link } from "react-router-dom";

interface ServiceProps extends GVServiceCardProps {
    type?: string;
    link?: string;
  }

  export const ServicesList: React.FC<{theme?: string}> = (props: any) => {
    const services = [
        {
          title: "JuraTax Online 2020",
          type: "Work",
          color: "red",
          maintained: true,
          img: "https://guichet.jura.ch/theme/images/presta-icons/JTO.png",
        },
        {
          title: "First Service Test",
          type: "Work",
          color: "red",
          maintained: true,
          icon: "campaign",
        },
        {
          title: "Second Service Test",
          type: "Formation",
          color: "yellow",
          maintained: true,
          icon: "camera",
        },
        {
          title: "Hunting License",
          type: "Environment",
          img: "https://guichet.jura.ch/theme/images/presta-icons/wild.png ",
          color: "green",
          link: "Hunting",
        },
        {
          title: "Third Service Test",
          type: "Population",
          color: "lightblue",
          maintained: true,
          icon: "accessibility",
        },
        {
          title: "Hello, awesome World",
          type: "Information",
          color: "blue",
          maintained: true,
          icon: "local_police",
        },
        {
          title:
            "Financial contribution in favour of the general activity of the sports entities",
          type: "Population",
          color: "purple",
          maintained: true,
          img: "https://guichet.jura.ch/theme/images/presta-icons/SportsMoney.png",
        },
        {
          title: "Taxes calculator",
          type: "Work",
          color: "lightgray",
          maintained: true,
          icon: "euro",
        },
      ] as ServiceProps[];

    return <GVGrid className="w100" alignHorizontal="left" alignVertical="top">
    {services
      .filter(
        (s: ServiceProps) =>
          s.type === props.theme || props.theme === undefined
      )
      .map((service: ServiceProps) => (
        <GVGridItem key={service.title} xs={12} s={12} m={6} l={4} xl={3}>
          <div style={{ margin: 6 }}>
            {service.link ? (
              <Link
                to={`/Services/${props.theme}/${service.link}`}
              >
                <GVServiceCard
                  title={service.title}
                  locked={service.locked}
                  color={service.color}
                  maintained={service.maintained}
                  icon={service.icon}
                  img={service.img}
                />
              </Link>
            ) : (
              <GVServiceCard
                title={service.title}
                locked={service.locked}
                color={service.color}
                maintained={service.maintained}
                icon={service.icon}
                img={service.img}
              />
            )}
          </div>
        </GVGridItem>
      ))}
  </GVGrid>
}