import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * AUthentication Reducer State
 *
 * @author Lucas Fridez <lucas.fridez@he-arc.ch>
 * @interface AuthState
 */
interface AuthState {
    token: string | null;
    isAuthenticated: boolean;
}

// Initial state
const initialState: AuthState = {
    token: null,
    isAuthenticated: false,
};

// Auth reducer
const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        saveToken(state, { payload }: PayloadAction<string>) {
            if (payload) {
                state.token = payload;
            }
        },
        clearToken(state) {
            state.token = null;
        },
        setAuthState(state, { payload }: PayloadAction<boolean>) {
            state.isAuthenticated = payload;
        },
    },
});

export const { saveToken, clearToken, setAuthState } = auth.actions;

export default auth.reducer;