import { GVCard, GVGrid, GVGridItem } from "@isc/egov-ui/src";
import { GVButton } from "@isc/egov-ui/src/GVButton/GVButton";

import React from "react";
import StylesProvider from "@isc/egov-ui/src/Misc/GVStyleProvider/GVStyleProvider";
import { Link } from "react-router-dom";

const providers = [
  {
    name: "ePortal",
    img: "https://www.igovportal.ch/Htdocs/Images/logo.svg",
    text:
      "This page allows developers to log into ePortal React version. This POC is developed by Lucas Fridez, Artionet Web Agency.",
  },
  {
    name: "SwissId",
    img:
      "https://www.swissid.ch/.resources/swisssign-frontend/webresources/aurelia/assets/images/logo-swissid.svg",
    text:
      "Avec SwissID, vous vous connectez de manière simple et sécurisée à des services en ligne suisses. SwissID est un service gratuit de SwissSign Group.",
  },
];

export default function LoginProviderView() {
  return (
    <StylesProvider injectFirst>
      <div className="row horizontal-center vertical-center background-gradient vw100 vh100">
        <GVGrid className="vertical-unset" alignHorizontal="center">
          {providers.map((el) => (
            <GVGridItem
              key={el.name}
              xs={12}
              s={12}
              m={6}
              l={3}
              xl={2}
              className="ma-1"
            >
              <GVCard className="h100">
                <div className="row column h100 vertical-center horizontal-spaceBetween">
                  <img src={el.img} className="m-0-auto mt-1" alt="" />
                  <p className="m-0-auto ta-center mt-2">{el.text}</p>
                  <div className="m-0-auto">
                    {el.name === "SwissId" && (
                      <GVButton
                        disabled={el.name === "SwissId"}
                        title={el.name}
                        type="primary"
                      />
                    )}
                    {el.name !== "SwissId" && (
                      <Link to="/Login">
                        <GVButton title={el.name} type="primary" />
                      </Link>
                    )}
                  </div>
                </div>
              </GVCard>
            </GVGridItem>
          ))}
        </GVGrid>
      </div>
    </StylesProvider>
  );
}
