import { GVCard, GVDatePicker, GVGrid, GVGridItem } from "@isc/egov-ui/src";
import { GVInput } from "@isc/egov-ui/src/Form/GVInput/GVInput";
import { GVButton } from "@isc/egov-ui/src/GVButton/GVButton";

import React from "react";
import StylesProvider from "@isc/egov-ui/src/Misc/GVStyleProvider/GVStyleProvider";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function SignUpView() {
  const SignupSchema = Yup.object().shape({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    birthdate: Yup.date()
      .required("Required")
      .max(new Date(2020, 11, 11), "You must be major"),
    password: Yup.string().min(6, "Too Short!").required("Required"),
    repeatpassword: Yup.string()
      .min(6, "Too Short!")
      .oneOf([Yup.ref("password"), null], "Passwords must match !"),
  });

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      birthdate: new Date(),
      password: "",
      repeatpassword: "",
    },
    validationSchema: SignupSchema,
    onSubmit(values) {
      console.log(values);
      history.push("/Home");
    },
  });
  return (
    <StylesProvider injectFirst>
      <div className="row horizontal-center vertical-center background-gradient vw100 vh100">
        <GVCard className="login-form">
          <form className="row" onSubmit={formik.handleSubmit}>
            <GVGrid alignHorizontal="center" alignVertical="top">
              <div className="row column vertical-center horizontal-center">
                <img
                  src="https://www.igovportal.ch/Htdocs/Images/logo.svg"
                  className="m-0-auto mt-1"
                  alt=""
                />
                <p className="m-0-auto ta-center mt-2">
                  This page allows developers to sign up into ePortal React
                  version. This POC is developed by Lucas Fridez, Artionet Web
                  Agency.
                </p>
              </div>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVInput
                  defaultValue={formik.values.lastname}
                  id="lastname"
                  label="Lastname"
                  required
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.lastname &&
                      formik.touched.lastname) as boolean
                  }
                  errorMessage={formik.errors.lastname}
                />
              </GVGridItem>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVInput
                  defaultValue={formik.values.lastname}
                  id="firstname"
                  label="Firstname"
                  required
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.lastname &&
                      formik.touched.lastname) as boolean
                  }
                  errorMessage={formik.errors.lastname}
                />
              </GVGridItem>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVInput
                  id="email"
                  defaultValue={formik.values.email}
                  label="Email"
                  name="email"
                  required
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.email && formik.touched.email) as boolean
                  }
                  errorMessage={formik.errors.email}
                />
              </GVGridItem>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVDatePicker
                  id="birthdate"
                  label="Birthday"
                  name="birthdate"
                  required
                  defaultValue={formik.values.birthdate}
                  onChange={(date: any) =>
                    formik.setFieldValue("birthdate", date)
                  }
                  error={
                    (formik.errors.birthdate &&
                      formik.touched.birthdate) as boolean
                  }
                  errorMessage={formik.errors.birthdate as string}
                />
              </GVGridItem>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVInput
                  id="password"
                  type="password"
                  defaultValue={formik.values.password}
                  label="Password"
                  name="password"
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.password &&
                      formik.touched.password) as boolean
                  }
                  errorMessage={formik.errors.password}
                  required
                />
              </GVGridItem>
              <GVGridItem xs={12} s={12} m={12} l={6}>
                <GVInput
                  id="repeatpassword"
                  type="password"
                  defaultValue={formik.values.repeatpassword}
                  label="Repeat Password"
                  name="repeatpassword"
                  onChange={formik.handleChange}
                  error={
                    (formik.errors.repeatpassword &&
                      formik.touched.repeatpassword) as boolean
                  }
                  errorMessage={formik.errors.repeatpassword}
                  required
                />
              </GVGridItem>
              <GVGridItem allWidths={12} className="row horizontal-center">
                <GVButton
                  variant="submit"
                  icon="login"
                  title="Sign Up"
                  type="primary"
                />
              </GVGridItem>
            </GVGrid>
          </form>
        </GVCard>
      </div>
    </StylesProvider>
  );
}
