import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Notifications Reducer State
 *
 * @author Lucas Fridez <lucas.fridez@he-arc.ch>
 * @interface NotificationsState
 */
interface NotificationsState {
    messages: number;
    documents: number;
};

// Initial state
const initialState: NotificationsState = {
    messages: 0,
    documents: 0
};

// Notification slice
const notifications = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setNotifications(state, { payload }: PayloadAction<NotificationsState>) {
            return (state = payload);
        },
    },
});

export const { setNotifications } = notifications.actions;

export default notifications.reducer;