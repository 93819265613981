import React, { useEffect } from "react";
import "@isc/egov-ui/src/Theming/_global.scss";
import { Container } from "./components/Container";
import { RoutesManager } from "./router/RoutesManager";

import { Switch, Route, Redirect } from "react-router-dom";
import HomeView from "./views/HomeView";
import LoginView from "./views/LoginView";
import SignUpView from "./views/SignUpView";
import LoginProviderView from "./views/LoginProviderView";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import ServiceView from "./views/ServiceView";
import { GVOverlay } from "@isc/egov-ui/src";
import StylesProvider from "@isc/egov-ui/src/Misc/GVStyleProvider/GVStyleProvider";
import { RootState } from "./store/rootReducer";
import { useSelector } from "react-redux";
import DocumentsView from "./views/DocumentsView";
import MessagesView from "./views/MessagesView";
import HuntingServiceView from "./views/HuntingServiceView";
import { ServiceData, StorageManager } from "./logic/StorageManager";
import { useSnackbar } from "notistack";

function App() {
  const isOverlayOpen = useSelector((state: RootState) => state.overlay.isOpen);
  const overlayMessage = useSelector(
    (state: RootState) => state.overlay.message
  );

  const handlerIsNowOnline = (ev: Event) => {
    StorageManager.pullArray("services").forEach((serviceData: ServiceData) => {
      enqueueSnackbar(
        `Data for prestation ${serviceData.name} sent to server !`,
        {
          variant: "success",
        }
      );
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.addEventListener("online", handlerIsNowOnline);
    return () => {
      window.removeEventListener("online", handlerIsNowOnline);
    };
  });

  return (
    // <h1>Hello, first !</h1>
    // <p>
    //   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo officiis
    //   voluptates velit exercitationem facere nemo odio necessitatibus, quis
    //   beatae voluptatum ducimus. Temporibus saepe tempora id sequi cum est,
    //   quae ipsam?
    // </p>

    // <h2>Description</h2>
    // <p>
    //   Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus quo
    //   officiis natus quia repudiandae est itaque repellendus corrupti,
    //   laboriosam quas incidunt minus architecto. Recusandae explicabo ullam
    //   iste aliquam quia odit.
    // </p>
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      {/* <ul>
        <li>No container :</li>
        {RoutesManager.getRoutes(false).map((route) => {
          return (
            <li>
              <Link to={route.path}>{route.name}</Link>
            </li>
          );
        })}
        <li>In container :</li>
      </ul> */}
      <Switch>
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>
        <Route
          path={RoutesManager.ROUTES.LoginProvider.path}
          component={LoginProviderView}
        />
        <Route path={RoutesManager.ROUTES.Login.path} component={LoginView} />
        <Route path={RoutesManager.ROUTES.SignUp.path} component={SignUpView} />
        <Container>
          <Route path="/Home" component={HomeView} />
          <Route path="/Documents" component={DocumentsView} />
          <Route path="/Messages" component={MessagesView} />
          <Route exact path="/Services" component={ServiceView} />
          <Route
            exact
            path="/Services/Environment/Hunting"
            component={HuntingServiceView}
          />
          <Route exact path="/Services/:theme" component={ServiceView} />
        </Container>
      </Switch>
      <StylesProvider injectFirst>
        <GVOverlay open={isOverlayOpen} text={overlayMessage}></GVOverlay>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
