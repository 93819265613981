import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * User interface
 *
 * @author Lucas Fridez <lucas.fridez@he-arc.ch>
 * @interface User
 */
interface User {
    firstname: string;
    lastname: string;
    email: string;
};

// User Slice
const user = createSlice({
    name: 'user',
    initialState: null as User | null,
    reducers: {
        setUser(state, { payload }: PayloadAction<User>) {
            return (state = payload);
        },
    },
});

export const { setUser } = user.actions;

export default user.reducer;