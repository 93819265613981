import axios from "axios";
import { LatLng } from "leaflet";

/**
 * Validator static class
 * Contain all validations for text fields
 *
 * @author Lucas Fridez <lucas.fridez@he-arc.ch>
 * @export
 * @class Validator
 */
export class Geolocation {

    private static readonly API = "https://api.bigdatacloud.net/data/reverse-geocode-client";

    private static getPosition() {
        // Simple wrapper
        return new Promise((res, rej) => {
            navigator.geolocation.getCurrentPosition(res, rej);
        });
    }

    private static async getCityName(lat: number, long: number) {
        if (navigator.onLine) {

            let response = await axios.get(`${this.API}?latitude=${lat}&longitude=${long}`);
            return response.data.locality;
        }
        return "";

        //         console.log(response.data.locality);
        //         return response.data.locality;
    }

    static async getCurrentPosition() {
        const pos: any = await this.getPosition();
        return new LatLng(pos.coords.latitude, pos.coords.longitude);
    }

    static async getCurrentCity() {
        const pos: any = await this.getPosition();
        const city: any = await this.getCityName(pos.coords.latitude, pos.coords.longitude);

        return city;
    }
}