import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Overlay Reducer State
 *
 * @Overlayor Lucas Fridez <lucas.fridez@he-arc.ch>
 * @interface OverlayState
 */
interface OverlayState {
    message: string
    isOpen: boolean;
}

// Initial state
const initialState: OverlayState = {
    message: "Loading...",
    isOpen: false,
};

// Overlay reducer
const Overlay = createSlice({
    name: 'Overlay',
    initialState,
    reducers: {
        showOverlay(state, { payload }: PayloadAction<string>) {
            state.isOpen = true;
            state.message = payload;
        },
        hideOverlay(state) {
            state.isOpen = false;
            state.message = "Loading...";
        }
    },
});

export const { showOverlay, hideOverlay } = Overlay.actions;

export default Overlay.reducer;