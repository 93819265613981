import { GVLink } from "@isc/egov-ui/src/GVLink/GVLink";
import { GVBreadcrumb } from "@isc/egov-ui/src/Misc/GVBreadcrumb/GVBreadcrumb";
import pkg from "../../package.json";
import { GVContainer } from "@isc/egov-ui/src";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";

const links = [
    <GVLink
      color="white"
      underline="always"
      href="#"
      text="Aide"
      title="Aide sur l'utilisation du guichet"
    />,
    <GVLink
      color="white"
      underline="always"
      href="#"
      text="Cookies"
      title="En savoir plus sur les cookies de ce site"
    />,
    <GVLink
      color="white"
      underline="always"
      href="#"
      text="Contact"
      title="Prendre contact avec le Guichet Virtuel"
    />,
    <GVLink
      color="white"
      underline="always"
      href="#"
      text="Termes et conditions"
      title="Découvrir les termes et conditions"
    />,
    <GVLink
      color="white"
      underline="always"
      href="#"
      text="Impressum"
      title="Voir l'impressum"
    />,
  ],
  accessibility = {
    paragraph: `Version v${pkg.version}. Ce site est conforme à l’accessibilité niveau AA`,
    accessibilityParagraph: `En savoir plus sur l’accessibilité`,
    accessibilityTitle: "Découvrir les règles d'accessibilités",
  },
  navLinks = [
    {
      text: "Environment",
      to: "/Services/Environment",
      icon: "eco",
      active: false,
    },
    {
      text: "Enterprise & Work",
      to: "/Services/Work",
      icon: "work",
      active: false,
    },
    {
      text: "Formation & School",
      to: "/Services/Formation",
      icon: "school",
      active: false,
    },
    {
      text: "Population",
      to: "/Services/Population",
      icon: "people",
      active: false,
    },
    {
      text: "Information",
      to: "/Services/Information",
      icon: "history_edu",
      active: false,
    },
    {
      text: "Tools",
      to: "/Services/Tools",
      icon: "construction",
      active: false,
    },
  ];

const breadcrumbNameMap: { [key: string]: string } = {
  "/": "iGovPortal",
  "/Services": "Services",
  "/Documents": "Documents",
  "/Messages": "Messages",
  "/Services/Environment": "Environment",
  "/Services/Environment/Hunting": "Hunting",
  "/Services/Work": "Work",
  "/Services/Formation": "Formation",
  "/Services/Population": "Population",
  "/Services/Information": "Information",
  "/Services/Tools": "Tools",
};

export const Container: React.FC = ({ children }) => {
  const pathnames = window.location.pathname.split("/").filter((x) => x);
  const isLoggedIn = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return (
    <GVContainer
      header={{
        logged: isLoggedIn,
        loginWrapper: (children: any, args: any) => (
          <Link to="/LoginProviders">{children}</Link>
        ), 
        ribbonText: "DEV",
        onMenuClick: (e: React.MouseEvent) => console.log("Hello"),
        logo: "https://igovportal-v5.egov4.ch/Htdocs/Images/logo.svg",
      }}
      menu={{
        linkWrapper: (children: any, args: any) => (
          <NavLink to={args.to} activeClassName="active" className="row nowrap vertical-center w100">{children}</NavLink>
        ),
        links: navLinks,
      }}
      footer={{
        img: "https://igovportal-v5.egov4.ch/Htdocs/Images/logo_bottom.png",
        links: links,
        accessibility: accessibility,
      }}
    >
      <GVBreadcrumb>
        <Link color="primary" to="/">
          {breadcrumbNameMap["/"]}
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          return last ? (
            <p color="textPrimary" key={to}>
              {breadcrumbNameMap[to]}
            </p>
          ) : (
            <Link color="primary" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </Link>
          );
        })}
      </GVBreadcrumb>
      {children}
    </GVContainer>
  );
};
