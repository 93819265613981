import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ServicesList } from "../components/ServicesList";

interface RouterProps {
  
}

interface HomeViewProps extends RouteComponentProps<RouterProps> {
  
}

class TopicDetail extends React.Component<HomeViewProps> {
  render(): any {
    return (
      <div>
        <ServicesList/>
      </div>
    );
  }
}

export default withRouter(TopicDetail);
