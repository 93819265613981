import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// Redux
import { Provider } from "react-redux";
import store from "./store/store";

const rootElement = document.getElementById("root");
const iGovPortal = (
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

// if (rootElement && rootElement.hasChildNodes()) {
//   console.log("hydrate");
  
//   hydrate(iGovPortal, rootElement);
// } else {
//   console.log("render");
    render(iGovPortal, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();