import { GVDataTable } from "@isc/egov-ui/src/GVDataTable/GVDataTable";
import { GVIconButton } from "@isc/egov-ui/src/GVIconButton/GVIconButton";

import React from "react";

export default function MessagesView() {
  const columns = [
    { field: "id", headerName: "ID", hide: true, width: 10 },
    { field: "date", headerName: "Date", flex: 1,
    renderCell: (params: any) => (
      params.row.read ? <strong>{params.row.date}</strong> : params.row.date
    ), },
    {
      field: "author",
      headerName: "Author",
      flex: 1.5,
      renderCell: (params: any) => (
        params.row.read ? <strong>{params.row.author}</strong> : params.row.author
      ),
    },
    {
      field: "description",
      headerName: "Description",
      type: "text",
      flex: 5,
      renderCell: (params: any) => (
        params.row.read ? <strong>{params.row.description}</strong> : params.row.description
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.5,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (
        <GVIconButton
          variant="primary"
          icon="remove_red_eye"
          onClick={(e) => {
            console.log(e.target, params.row.id);
          }}
        ></GVIconButton>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: true,
      important: false,
    },
    {
      id: 2,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: true,
      important: true,
    },
    {
      id: 3,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: false,
    },
    {
      id: 4,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: false,
    },
    {
      id: 5,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: false,
    },
    {
      id: 6,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: true,
    },
    {
      id: 7,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: true,
    },
    {
      id: 8,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: true,
    },
    {
      id: 9,
      date: "16.12.2020 à 18h40",
      author: "Contributions Service",
      description:
        "Commande d’extrait du registre du commerce - Veuillez trouver ci-joint le(s) document(s) commandé(s)",
      actions: "",
      read: false,
      important: true,
    },
  ];

  return (
    <>
      <h2>My Messages</h2>

      <GVDataTable rows={rows} columns={columns} />
    </>
  );
}
